
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    style="text-align:left;"
    @close="closeDialog"
    :close-on-click-modal="false"
  >
    <div class="user-panel" v-loading="loading">
      <el-form ref="form" :model="formModel" :rules="ruleValidate" :label-width="'150px'">
        <el-row>
          <el-col :span="12">
            <el-form-item label="显示抓拍图片" prop="deviceNo">
              <el-switch
                v-model="formModel.showCapPic"
                active-color="#13ce66"
                active-text="是"
                inactive-color="#ff4949"
                inactive-text="否"
                ></el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="显示用户信息" prop="deviceNo">
              <el-switch
                v-model="formModel.showUserInfo"
                active-color="#13ce66"
                active-text="是"
                inactive-color="#ff4949"
                inactive-text="否"
                ></el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="叠加用户信息" prop="deviceNo">
              <el-switch
                v-model="formModel.overlayUserInfo"
                active-color="#13ce66"
                active-text="是"
                inactive-color="#ff4949"
                inactive-text="否"
                ></el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="启用语音提示" prop="deviceNo">
              <el-switch
                v-model="formModel.voicePrompt"
                active-color="#13ce66"
                active-text="是"
                inactive-color="#ff4949"
                inactive-text="否"
                ></el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="保存抓拍图片" prop="deviceNo">
              <el-switch
                v-model="formModel.saveCapPic"
                active-color="#13ce66"
                active-text="是"
                inactive-color="#ff4949"
                inactive-text="否"
                ></el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="测温开关使能" prop="deviceNo">
              <el-switch
                v-model="formModel.thermalEnabled"
                active-color="#13ce66"
                active-text="开"
                inactive-color="#ff4949"
                inactive-text="关"
                ></el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="温度阈值上限" prop="deviceNo">
                <el-input
                    v-model.number="formModel.highestThermalThreshold"
                    style="width: 100px"
                >
                <template slot="append">度</template>
                </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="温度阈值下限" prop="deviceNo">
                <el-input
                    v-model.number="formModel.lowestThermalThreshold"
                    style="width: 100px"
                >
                <template slot="append">度</template>
                </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="体温超标允许开门" prop="deviceNo">
                <el-switch
                    v-model="formModel.thermalDoorEnabled"
                    active-color="#13ce66"
                    active-text="是"
                    inactive-color="#ff4949"
                    inactive-text="否"
                ></el-switch>
            </el-form-item>
          </el-col>
        </el-row>        
        <el-row>
          <el-col :span="12">
            <el-form-item label="是否开启口罩检测">
                <el-switch
                    v-model="maskDetection.enable"
                    active-color="#13ce66"
                    active-text="是"
                    inactive-color="#ff4949"
                    inactive-text="否"
                ></el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="检测未带口罩策略">
              <el-select v-model="maskDetection.noMaskStrategy">
                <el-option label="提示且开门" value="tipsAndOpenDoor"></el-option>
                <el-option label="不提示且开门" value="noTipsAndOpenDoor"></el-option>
                <el-option label="提示且不开门" value="tipsAndNotOpenDoor"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import ehomeApi from "@/api/base/ehome";

export default {
  props: ["deviceNo", "title", "companyResult"],
  data() {
    return {
      formModel: {},
      maskDetection: {
        "enable": true,          
        "noMaskStrategy": ""
      },
      ruleValidate: {},
      showDialog: true,
      loading: false,
      submitting: false,
      cfgUrl: "/ISAPI/AccessControl/AcsCfg?format=json",
      maskDetectionUrl: "/ISAPI/AccessControl/maskDetection?format=json"
    }
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate(valid => {
        self.loading = true;

        //保存测温策略
        ehomeApi.putXMLConfig(
            self.deviceNo,self.cfgUrl,{
                "AcsCfg": self.formModel
            },
        ).then(response=>{
          var jsonData = response.data;

          if(!jsonData.success){
            self.$message({
              message: jsonData.message + "",
              type: "warning"
            });
          }

          //保存口罩设置
          return  ehomeApi.putXMLConfig(
            self.deviceNo,
            self.maskDetectionUrl,
            {
                "MaskDetection": self.maskDetection
            }
          )
        }).then(response=>{
          self.loading = false;
          var jsonData = response.data;

          if (jsonData.success) {
            self.$message({
              message: "保存成功!",
              type: "success"
            });

            self.$emit("close", true);
          } else {
            self.$message({
              message: jsonData.message + "",
              type: "warning"
            });
          }
        });
      });
    },
  },
  mounted: function() {
    var self = this;
    
    self.loading = true;

    //获取测温策略
    ehomeApi.getXMLConfig(self.deviceNo,self.cfgUrl)
    .then(response => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.success) {
            var data = eval('(' + jsonData.data + ')');

            self.formModel = data.AcsCfg;
        } else {
            self.$message.error(jsonData.message + "");
        }
    })
    .catch(error => {
        self.$message.error(error + "");
    });

    //获取口罩设置
    ehomeApi.getXMLConfig(self.deviceNo,self.maskDetectionUrl)
    .then(response => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.success) {
            var data = eval('(' + jsonData.data + ')');

            self.maskDetection = data.MaskDetection;
        } else {
            self.$message.error(jsonData.message + "");
        }
    })
    .catch(error => {
        self.$message.error(error + "");
    });
  }
};
</script>