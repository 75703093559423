<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">基础信息</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/deviceInfo">设备管理</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form
      ref="queryForm"
      :model="queryModel"
      inline
      class="demo-form-inline"
    >
      <el-form-item label="所属单位" prop="companyId">
        <!-- <el-select
          v-model="queryModel.companyId"
          size="mini"
          filterable
          placeholder="请选择"
          style="width:280px"
        >
          <el-option
            v-for="company in companyResult"
            :key="company.id"
            :label="company.name"
            :value="company.id"
          ></el-option>
        </el-select>-->
        <el-select-tree
          size="mini"
          :props="props"
          :options="companyResult"
          v-model="queryModel.companyId"
          height="200"
        ></el-select-tree
        >&nbsp;
      </el-form-item>
      <el-form-item label="关联单位" prop="companyName">
        <el-input
          type="text"
          size="mini"
          v-model="queryModel.companyName"
        ></el-input>
      </el-form-item>
      <el-form-item label="设备编号" prop="deviceNo">
        <el-input
          type="text"
          size="mini"
          v-model="queryModel.deviceNo"
        ></el-input>
      </el-form-item>
      <el-form-item label="设备别名" prop="aliasName">
        <el-input
          type="text"
          size="mini"
          v-model="queryModel.aliasName"
        ></el-input>
      </el-form-item>
      <el-form-item label="内部编号" prop="code">
        <el-input
          type="text"
          size="mini"
          v-model="queryModel.code"
          style="width: 100px"
        ></el-input>
      </el-form-item>
      <el-form-item label="立柱颜色" prop="columnColor">
        <el-select
          v-model="queryModel.columnColor"
          size="mini"
          style="width: 100px"
          clearable
        >
          <el-option
            v-for="(item, index) in columnColorList"
            :label="item.name"
            :value="item.value"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="立柱高度" prop="columnHeight">
        <el-select
          v-model="queryModel.columnHeight"
          size="mini"
          style="width: 100px"
          clearable
        >
          <el-option
            v-for="(item, index) in columnHeightList"
            :label="item.name"
            :value="item.value"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="模块类型" prop="type">
        <el-select
          v-model="queryModel.type"
          size="mini"
          style="width: 100px"
          clearable
        >
          <el-option
            v-for="(item, index) in moduleTypeList"
            :label="item.name"
            :value="item.value"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="设备类型" prop="deviceType">
        <el-select
          v-model="queryModel.deviceType"
          size="mini"
          style="width: 100px"
          clearable
        >
          <el-option
            v-for="(item, index) in deviceTypeList"
            :label="item.name"
            :value="item.value"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="设备厂家" prop="manufacturer">
        <el-select
          v-model="queryModel.manufacturer"
          size="mini"
          style="width: 100px"
          clearable
        >
          <el-option
            v-for="(item, index) in manufacturerList"
            :label="item.name"
            :value="item.value"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
          >查询</el-button
        >&nbsp;
        <el-button
          type="info"
          size="mini"
          style="margin-left: 8px"
          @click="handleReset('queryForm')"
          >重置</el-button
        >&nbsp;
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group">
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-circle-plus"
        @click="handleAdd"
        >新增</el-button
      >
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-remove"
        :disabled="multipleSelection.length == 0"
        @click="handleBatchDelete"
        >删除选中项</el-button
      >
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-search"
        @click="showChannelListModal = true"
        >设备连接列表</el-button
      >
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-download"
        @click="exportXls()"
        >导出</el-button
      >
    </el-row>
    <el-table
      ref="formTable"
      :data="tableData"
      v-loading="loading"
      :element-loading-text="loadingText"
      stripe
      :height="tableHeight"
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column
        type="index"
        label="序号"
        :index="indexMethod"
        width="50"
      ></el-table-column>
      <el-table-column
        prop="deviceNo"
        label="设备编号"
        width="200"
        sort-by="a.device_no"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        prop="code"
        label="内部编号"
        width="100"
        sort-by="a.code_"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        prop="companyName"
        label="单位"
        width="250"
        show-overflow-tooltip
        sort-by="b.name_"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        prop="aliasName"
        label="设备别名"
        width="200"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="isOnline"
        label="运行状态"
        width="100"
        sort-by="a.is_online"
        sortable="custom"
      >
        <template slot-scope="{ row }">
          <div
            v-if="row.isOnline"
            style="
              border-radius: 30px;
              background-color: #67c23a;
              width: 20px;
              height: 20px;
            "
          ></div>
          <div
            v-if="!row.isOnline"
            style="
              border-radius: 30px;
              background-color: #f56c6c;
              width: 20px;
              height: 20px;
            "
          ></div>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="ipAddress" label="ip地址" width="150"></el-table-column>
      <el-table-column prop="port" label="端口" width="80"></el-table-column> -->
      <!-- <el-table-column prop="unlockPassword" label="开锁密码" width="150"></el-table-column>
      <el-table-column prop="codeAddress" label="二维码地址" width="180"></el-table-column>-->
      <el-table-column prop="typeN" label="模块类型"></el-table-column>
      <el-table-column prop="deviceTypeN" label="设备类型"></el-table-column>
      <el-table-column prop="columnColorN" label="立柱颜色"></el-table-column>
      <el-table-column prop="columnHeightN" label="立柱高度"></el-table-column>
      <el-table-column prop="manufacturerN" label="设备厂家"></el-table-column>
      <el-table-column
        prop="heartbeatUpdateTime"
        sort-by="a.heartbeat_update_time"
        sortable="custom"
        label="心跳更新时间"
        width="160"
      ></el-table-column>
      <el-table-column
        prop="updateTime"
        label="记录更新时间"
        width="160"
      ></el-table-column>
      <el-table-column
        prop="remoteOpenStartTime"
        label="远程开门开始时间"
      ></el-table-column>
      <el-table-column
        prop="remoteOpenEndTime"
        label="远程开门结束时间"
      ></el-table-column>
      <el-table-column label="照片库" width="120">
        <template slot-scope="{ row }">
          <el-link
            v-if="row.manufacturer == '1'"
            type="primary"
            @click="handleViewPersonLib(row)"
            icon="el-icon-view"
            :disabled="!row.isOnline"
            >查看</el-link
          >
          &nbsp;
          <el-link
            type="primary"
            @click="handleExistPersonImageInDevice(row)"
            icon="el-icon-refresh"
            :disabled="!row.isOnline"
            >同步</el-link
          >
        </template>
      </el-table-column>
      <el-table-column label="操作" width="250" fixed="right">
        <template slot-scope="{ row }">
          <el-row>
            <el-col :span="8">
              <el-link type="primary" @click="handleEdit(row)">编辑</el-link>
            </el-col>
            <el-col :span="8">
              <el-link type="danger" @click="handleDelete(row)">删除</el-link>
            </el-col>
            <el-col :span="8">
              <el-link type="primary" @click="handlePoint(row)">定位</el-link>
            </el-col>
            <!-- <el-col :span="6">
              <el-link type="primary" @click="handleBindCompany(row)">单位关联</el-link>
            </el-col> -->
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-link type="primary" @click="handleBindPerson(row)"
                >用户关联</el-link
              >
            </el-col>
            <el-col :span="8" v-if="row.manufacturer == '1'">
              <el-link type="primary" @click="handleEditRule(row)"
                >验证规则</el-link
              >
            </el-col>
            <el-col :span="8" v-if="row.manufacturer == '2'">
              <el-link type="primary" @click="handleAcsCfg(row)"
                >参数设置</el-link
              >
            </el-col>
            <el-col :span="8">
              <el-link type="primary" @click="handleReboot(row)"
                >重启设备</el-link
              >
            </el-col>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <deviceInfo-detail
      v-if="showModal"
      :businessKey="businessKey"
      :title="modalTitle"
      :companyResult="companyResult"
      @close="onDetailModalClose"
    ></deviceInfo-detail>
    <!-------------设备验证规则start------------------>
    <el-dialog
      title="设备验证规则"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      append-to-body
      width="800px"
    >
      <el-form
        :model="ruleModel"
        ref="ruleForm"
        :rules="ruleValidate"
        inline
        :label-width="'150px'"
        style="text-align: left"
        v-loading="ruleLoading"
      >
        <el-tabs type="card" v-model="activeName" @tab-click="handleTabClick">
          <el-tab-pane label="属性规则" name="1">
            <el-row>
              <el-col>
                <el-form-item label="开门模式">
                  <el-radio-group v-model="ruleModel.mode">
                    <el-radio :label="0">校验通过</el-radio>
                    <el-radio :label="1">刷脸</el-radio>
                    <el-radio :label="2">远程</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <el-form-item label="安全帽">
                  <el-radio-group v-model="ruleModel.needCap">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <el-form-item label="口罩">
                  <el-radio-group v-model="ruleModel.needMask">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <el-form-item label="测温">
                  <el-radio-group v-model="ruleModel.measureTemperature">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <template v-if="ruleModel.measureTemperature == 1">
              <el-row>
                <el-col>
                  <el-form-item label="位置">
                    <el-radio-group v-model="ruleModel.type">
                      <el-radio :label="0">额头</el-radio>
                      <el-radio :label="1">手腕</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col>
                  <el-form-item label="最低温度" prop="minimum">
                    <el-input-number
                      v-model="ruleModel.minimum"
                      placeholder="请输入最低温度"
                      :precision="2"
                      :step="0.1"
                      :max="45"
                      :min="30"
                      style="width: 200px"
                    ></el-input-number>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col>
                  <el-form-item label="最高温度" prop="maximum">
                    <el-input-number
                      v-model="ruleModel.maximum"
                      placeholder="请输入最高温度"
                      :precision="2"
                      :step="0.1"
                      :max="45"
                      :min="30"
                      style="width: 200px"
                    ></el-input-number>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col>
                  <el-form-item label="温度误差" prop="preAlarmOffset">
                    <el-input-number
                      v-model="ruleModel.preAlarmOffset"
                      placeholder="请输入温度误差"
                      :precision="2"
                      :step="0.1"
                      :max="1"
                      :min="0.1"
                      style="width: 200px"
                    ></el-input-number>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col>
                  <el-form-item label="报警温度" prop="alarmThreshold">
                    <el-input-number
                      v-model="ruleModel.alarmThreshold"
                      placeholder="请输入报警温度"
                      :precision="2"
                      :step="0.1"
                      :max="45"
                      :min="30"
                      style="width: 200px"
                    ></el-input-number>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col>
                  <el-form-item label="有效体温最低阈值" prop="minTemp">
                    <el-input-number
                      v-model="ruleModel.minTemp"
                      placeholder="请输入报警温度"
                      :precision="2"
                      :step="0.1"
                      :max="45"
                      :min="0"
                      style="width: 200px"
                    ></el-input-number>
                  </el-form-item>
                </el-col>
              </el-row>
            </template>            
            <div style="text-align: center">
                <el-button type="primary" @click="saveDeviceRule"
                  >保 存</el-button
                >
            </div>
          </el-tab-pane>
          <el-tab-pane label="人脸检测" name="2">
            <!-- <el-row>
          <el-col>
            <el-form-item label="最小瞳距(px)" prop="pupilDistanceMin">
              <el-input-number
                  v-model="ruleModel.pupilDistanceMin"
                  placeholder="请输入最小瞳距(px)"
                  :max="100"
                  :min="0"
                  style="width:200px"
                ></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="最大瞳距(px)" prop="pupilDistanceMax">
              <el-input-number
                  v-model="ruleModel.pupilDistanceMax"
                  placeholder="请输入最大瞳距(px)"
                  :max="100"
                  :min="0"
                  style="width:200px"
                ></el-input-number>
            </el-form-item>
          </el-col>
        </el-row> -->
            <el-row>
              <el-col>
                <el-form-item label="活体检测" prop="vivoDetection">
                  <el-radio-group v-model="ruleModel.vivoDetection">
                    <el-radio :label="1">开启</el-radio>
                    <el-radio :label="0">关闭</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <div style="text-align: center">
              <el-button type="primary" @click="saveDeviceFaceLivingAnalysis"
                >保 存</el-button
              >
            </div>
          </el-tab-pane>
          <el-tab-pane label="人脸库" name="3">
            <el-row>
              <el-col>
                <el-form-item label="1:N相似阈值" prop="similarityThreshold">
                  <el-input-number
                    v-model="ruleModel.similarityThreshold"
                    placeholder="请输入相似阈值"
                    :max="100"
                    :min="1"
                    style="width: 200px"
                  ></el-input-number>
                </el-form-item>
              </el-col>
            </el-row>
            <div style="text-align: center">
              <el-button type="primary" @click="saveMultipleValue"
                >保 存</el-button
              >
            </div>
          </el-tab-pane>
          <el-tab-pane label="体温补偿" name="4">
            <el-row>
              <el-col>
                <el-form-item label="原始值补偿" prop="valueCompensation">
                  <el-input-number
                    v-model="ruleModel.valueCompensation"
                    placeholder="请输入原始值补偿"
                    :step="0.1"
                    :max="10"
                    :min="-10"
                    style="width: 200px"
                  ></el-input-number>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <el-form-item label="额温转体温补偿" prop="bodyTemperature">
                  <el-input-number
                    v-model="ruleModel.bodyTemperature"
                    placeholder="请输入额温转体温补偿"
                    :step="0.1"
                    :max="10"
                    :min="-10"
                    style="width: 200px"
                  ></el-input-number>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <el-form-item label="运动抑制">
                  <el-radio-group v-model="ruleModel.motionInhibition">
                    <el-radio :label="1">开启</el-radio>
                    <el-radio :label="0">关闭</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <div style="text-align: center">
              <el-button type="primary" @click="saveTemperatureCompensation"
                >保 存</el-button
              >
            </div>
          </el-tab-pane>
          <el-tab-pane label="抗干扰模式" name="5">
            <el-row>
              <el-col>
                <el-form-item label="抗干扰模式">
                  <el-radio-group v-model="ruleModel.modeEnabled">
                    <el-radio :label="1">开启</el-radio>
                    <el-radio :label="0">关闭</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <div style="text-align: center">
              <el-button type="primary" @click="saveHeatMap">保 存</el-button>
            </div>
          </el-tab-pane>
          <el-tab-pane label="灯光状态" name="6">
            <el-row>
              <el-col>
                <el-form-item label="灯光节能">
                  <el-radio-group v-model="lightCfg.Enabled">
                    <el-radio :label="1">开启</el-radio>
                    <el-radio :label="0">关闭</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <el-form-item label="起效时间(分钟)">
                  <el-slider  v-model="lightCfg.Duration" show-input style="width:360px;"></el-slider>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <el-form-item label="计划时间">
                  <el-time-picker v-model="lightCfg.BeginTime" value-format="HH:mm:ss" style="width:150px;"></el-time-picker>
                  <el-time-picker v-model="lightCfg.EndTime" value-format="HH:mm:ss" style="width:150px;"></el-time-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <el-form-item label="补光灯节能亮度">
                  <el-input-number v-model="lightCfg.LightMinLevel"></el-input-number>
                </el-form-item>
              </el-col>
            </el-row>
            <div style="text-align: center">
              <el-button type="primary" @click="saveLightCfg">保 存</el-button>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-------------设备验证规则end------------------>
    <deviceInfo-ehome
      v-if="showEhomeModal"
      :deviceNo="selectedDevice.deviceNo"
      title="海康设备设置"
      @close="onEhomeModalClose"
    ></deviceInfo-ehome>
    <deviceInfo-map
      v-if="showMapModal"
      :businessKey="businessKey"
      :title="modalTitle"
      @close="onDetailModalClose"
    ></deviceInfo-map>
    <deviceInfo-channelList
      v-if="showChannelListModal"
      :companyResult="companyResult"
      @close="onDetailModalClose"
    ></deviceInfo-channelList>
    <devicePhoto-list
      :device="selectedDevice"
      v-if="showPhotoListModal"
      @close="onDevicePhotoListClose"
    >
    </devicePhoto-list>
  </div>
</template>
<script>
import Constant from "@/constant";
import DeviceInfoDetail from "./deviceInfo-detail";
import DeviceInfoEhome from "./deviceInfo-ehome";
import DeviceInfoChannelList from "./deviceInfo-channelList";
import DevicePhotoList from "./devicePhoto-list";
import DeviceInfoMap from "./deviceInfo-amap";
import deviceInfoApi from "@/api/base/deviceInfo";
import dataDictionaryApi from "@/api/sys/dataDictionary";
import companyInfoApi from "@/api/base/companyInfo";
import SelectTree from "@/components/SelectTree";
import pageUtil from "@/utils/page";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  name: "BaseDeviceInfoList",
  data() {
    var self = this;

    return {
      ruleValidate: {
        minimum: [{ required: true, message: "不能为空", trigger: "blur" }],
        maximum: [{ required: true, message: "不能为空", trigger: "blur" }],
        minTemp: [{ required: true, message: "不能为空", trigger: "blur" }],
        alarmThreshold: [
          { required: true, message: "不能为空", trigger: "blur" },
        ],
      },
      queryModel: {
        deviceNo: "",
        aliasName: "",
        companyId: "",
        companyName: "",
        code: "",
        columnColor: "",
        columnHeight: "",
        moduleType: "",
        deviceType: "",
      },
      loading: false,
      loadingText: "加载中",
      ruleLoading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      businessKey: "",
      companyResult: [],
      tableHeight: 400,
      dialogVisible: false,
      ruleDeviceId: "",
      ruleModel: {
        mode: "",
        needCap: "",
        needMask: "",
        measureTemperature: "",
        type: "",
        minimum: "",
        maximum: "",
        preAlarmOffset: "",
        alarmThreshold: "",
        mintemp: "",
        pupilDistanceMin: "",
        pupilDistanceMax: "",
        vivoDetection: "",
        similarityThreshold: "",
        valueCompensation: "",
        bodyTemperature: "",
        motionInhibition: "",
        modeEnabled: "",
        minTemp: "",
      },
      treeData: [],
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children",
      },
      showMapModal: false,
      showChannelListModal: false,
      showPhotoListModal: false,
      showEhomeModal: false,
      selectedDevice: {},
      columnColorList: [],
      columnHeightList: [],
      moduleTypeList: [],
      deviceTypeList: [],
      manufacturerList: [],
      activeName: "1",
      lightCfg: {
        "Enabled":0,
        "LightMinLevel":0,
        "Duration":0,
        "BeginTime":"00:00:00",
        "EndTime":"23:59:59"
      }
    };
  },
  created() {
    dataDictionaryApi
      .findByCatalogName({
        catalogName: "立柱颜色",
      })
      .then((response) => {
        var jsonData = response.data;
        this.columnColorList = jsonData.data;
      });

    dataDictionaryApi
      .findByCatalogName({
        catalogName: "立柱高度",
      })
      .then((response) => {
        var jsonData = response.data;
        this.columnHeightList = jsonData.data;
      });

    dataDictionaryApi
      .findByCatalogName({ catalogName: "模块类型" })
      .then((response) => {
        var jsonData = response.data;
        this.moduleTypeList = jsonData.data;
      });

    dataDictionaryApi
      .findByCatalogName({
        catalogName: "设备类型",
      })
      .then((response) => {
        var jsonData = response.data;
        this.deviceTypeList = jsonData.data;
      });

    dataDictionaryApi
      .findByCatalogName({
        catalogName: "设备厂家",
      })
      .then((response) => {
        var jsonData = response.data;
        this.manufacturerList = jsonData.data;
      });

    var self = this;
    companyInfoApi.treeList().then(function (response) {
      var jsonData = response.data;
      if (jsonData.result) {
        self.companyResult = jsonData.data;
      }
    });

    this.loadTree();
  },
  methods: {
    indexMethod(index) {
      return (this.pageIndex - 1) * this.pageSize + (index + 1);
    },
    loadTree() {
      var formData = new FormData();

      companyInfoApi.loadChildren(formData).then((resp) => {
        var jsonData = resp.data;

        if (jsonData.result) {
          this.treeData = jsonData.data;
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    loadChildren(tree, treeNode, resolve) {
      console.log(tree);

      var formData = new FormData();
      formData.append("parentId", tree.id);

      companyInfoApi.loadChildren(formData).then((resp) => {
        var jsonData = resp.data;

        if (jsonData.result) {
          resolve(jsonData.data);
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    changePage(pageIndex, exportFlag) {
      var self = this;

      self.loading = true;
      self.loadingText = "加载中...";

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);

      if (exportFlag != null) {
        formData.append("exportFlag", 1);
        formData.append("pageSize", 10000);
      } else {
        formData.append("pageSize", self.pageSize);
      }

      formData.append("deviceNo", self.queryModel.deviceNo);
      formData.append("aliasName", self.queryModel.aliasName);

      if (self.queryModel.companyId != null) {
        formData.append("companyId", self.queryModel.companyId);
      }

      if (self.queryModel.companyName != null) {
        formData.append("companyName", self.queryModel.companyName);
      }

      if (self.queryModel.code != null) {
        formData.append("code", self.queryModel.code);
      }

      if (self.queryModel.columnColor != null) {
        formData.append("columnColor", self.queryModel.columnColor);
      }

      if (self.queryModel.columnHeight != null) {
        formData.append("columnHeight", self.queryModel.columnHeight);
      }

      if (self.queryModel.moduleType != null) {
        formData.append("moduleType", self.queryModel.moduleType);
      }

      if (self.queryModel.deviceType != null) {
        formData.append("deviceType", self.queryModel.deviceType);
      }

      if (self.queryModel.manufacturer != null) {
        formData.append("manufacturer", self.queryModel.manufacturer);
      }

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      deviceInfoApi
        .pageList(formData)
        .then(function (response) {
          self.loading = false;
          var jsonData = response.data;

          if (jsonData.result) {
            if (exportFlag) {
              if (document.location.href.startsWith("https://")) {
                jsonData.data = jsonData.data.replace("http://", "https://");
              }

              //导出
              self.$message({
                showClose: true,
                type: "success",
                message: `报表已生成，<a href="${jsonData.data}">请点击链接下载</a>`,
                dangerouslyUseHTMLString: true,
                duration: 30000,
              });
            } else {
              //分页查看
              var page = jsonData.data;

              self.tableData = page.data;
              self.totalPages = page.totalPages;
              self.totalElements = page.recordsTotal;
            }
          } else {
            self.$message.warning(jsonData.message + "");
          }
        })
        .catch((error) => {
          self.loading = false;
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
    },
    sortChange(data) {
      console.log(data);

      this.field = data.column.sortBy;
      this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleAdd() {
      this.modalTitle = "新增";
      this.businessKey = "";
      this.showModal = true;
    },
    handleEdit(record) {
      this.modalTitle = "编辑";
      this.businessKey = record.id;
      this.showModal = true;
    },
    handleDelete(record) {
      var self = this;

      self
        .$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          deviceInfoApi.remove(record.id).then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              // var index = self.tableData.indexOf(record);
              // self.tableData.splice(index, 1);
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        });
    },
    handleBatchDelete() {
      var self = this;

      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });

      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deviceInfoApi.batchRemove(idList).then(function (response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        });
      });
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;
      this.showMapModal = false;
      this.showChannelListModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
    handleRestart(record) {
      //重启
    },
    handleClear(record) {
      //清楚人脸数据
    },
    handleQRcode(record) {
      //二维码地址
    },
    handleEditRule(record) {
      var self = this;
      self.loading = true;
      self.selectedDevice = record;

      var formData = new FormData();
      formData.append("deviceId", record.id);
      self.ruleDeviceId = record.id;

      // self.loading = false;
      // self.dialogVisible = true;

      deviceInfoApi.getDeviceRule(formData).then(function (response) {
        var jsonData = response.data;
        if (jsonData.result) {
          self.loading = false;
          self.dialogVisible = true;
          console.log(jsonData.data);
          //开关列表
          var ruleList = jsonData.data.RuleList;
          ruleList.forEach((item, index) => {
            if (item.Mode == "1") {
              self.ruleModel.needCap = item.Enabled;
            } else if (item.Mode == "2") {
              self.ruleModel.needMask = item.Enabled;
            } else if (item.Mode == "3") {
              self.ruleModel.measureTemperature = item.Enabled;
            }
          });
          //获取温度设置列表
          var temperatureRule = jsonData.data.TemperatureRule;
          self.ruleModel.type = temperatureRule.Type;
          self.ruleModel.minimum = temperatureRule.Minimum;
          self.ruleModel.maximum = temperatureRule.Maximum;
          self.ruleModel.preAlarmOffset = temperatureRule.PreAlarmOffset;
          self.ruleModel.alarmThreshold = temperatureRule.AlarmThreshold;

          self.ruleModel.minTemp = temperatureRule.Mintemp;
          //人脸检测
          //var faceMap = jsonData.data.faceMap;
          // self.ruleModel.pupilDistanceMin = faceMap.min;
          // self.ruleModel.pupilDistanceMax = faceMap.max;

          //活体检测
          if (jsonData.data.livingAnalysisEnabled == "1") {
            self.ruleModel.vivoDetection = 1;
          } else {
            self.ruleModel.vivoDetection = 0;
          }

          //人脸库
          self.ruleModel.similarityThreshold = jsonData.data.multipleValue;

          //体温补偿
          var temperatureCompensation = jsonData.data.temperatureCompensation;
          self.ruleModel.valueCompensation = temperatureCompensation.Original;
          self.ruleModel.bodyTemperature = temperatureCompensation.Body;
          self.ruleModel.motionInhibition =
            temperatureCompensation.MotionFilter;

          //抗干扰模式
          if (jsonData.data.modeEnabled == "1") {
            self.ruleModel.modeEnabled = 1;
          } else {
            self.ruleModel.modeEnabled = 0;
          }

          console.log(jsonData.data);
          self.ruleModel.mode = jsonData.data.mode;
        } else {
          self.loading = false;
          self.$alert(jsonData.message, "提示", {
            confirmButtonText: "确定",
          });
        }
      });
    },
    saveDeviceRule() {
      var self = this;
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          self.ruleLoading = true;
          var formData = new FormData();
          formData.append("deviceInfoId", self.ruleDeviceId);
          formData.append("mode", self.ruleModel.mode);
          formData.append("needCap", self.ruleModel.needCap);
          formData.append("needMask", self.ruleModel.needMask);
          formData.append(
            "measureTemperature",
            self.ruleModel.measureTemperature
          );
          formData.append("type", self.ruleModel.type);
          formData.append("minTemper", self.ruleModel.minimum);
          formData.append("maxTemper", self.ruleModel.maximum);
          formData.append("alarmThreshold", self.ruleModel.alarmThreshold);

          formData.append("minTemp", self.ruleModel.minTemp==null ? 0 : self.ruleModel.minTemp);

          deviceInfoApi.saveDeviceRule(formData).then(function (response) {
            var jsonData = response.data;
            if (jsonData.result) {
              self.$message({
                type: "success",
                message: "成功",
              });
            } else {
              self.$message({
                type: "success",
                message: jsonData.message,
              });
            }
            self.ruleLoading = false;
          });
        }
      });
    },
    saveDeviceFaceLivingAnalysis() {
      var self = this;

      self.ruleLoading = true;
      var formData = new FormData();
      formData.append("deviceInfoId", self.ruleDeviceId);
      formData.append("faceLivingAnalysis", self.ruleModel.vivoDetection);
      deviceInfoApi
        .saveDeviceFaceLivingAnalysis(formData)
        .then(function (response) {
          var jsonData = response.data;
          if (jsonData.result) {
            self.$message({
              type: "success",
              message: "成功",
            });
          } else {
            self.$message({
              type: "success",
              message: jsonData.message,
            });
          }
          self.ruleLoading = false;
        });
    },
    saveMultipleValue() {
      var self = this;
      self.ruleLoading = true;
      var formData = new FormData();
      formData.append("deviceInfoId", self.ruleDeviceId);
      formData.append("multipleValue", self.ruleModel.similarityThreshold);
      deviceInfoApi.saveMultipleValue(formData).then(function (response) {
        var jsonData = response.data;
        if (jsonData.result) {
          self.$message({
            type: "success",
            message: "成功",
          });
        } else {
          self.$message({
            type: "success",
            message: jsonData.message,
          });
        }
        self.ruleLoading = false;
      });
    },
    saveTemperatureCompensation() {
      var self = this;
      self.ruleLoading = true;
      var formData = new FormData();
      formData.append("deviceInfoId", self.ruleDeviceId);
      formData.append("original", self.ruleModel.valueCompensation);
      formData.append("body", self.ruleModel.bodyTemperature);
      formData.append("motionFilter", self.ruleModel.motionInhibition);
      deviceInfoApi
        .saveTemperatureCompensation(formData)
        .then(function (response) {
          var jsonData = response.data;
          if (jsonData.result) {
            self.$message({
              type: "success",
              message: "成功",
            });
          } else {
            self.$message({
              type: "success",
              message: jsonData.message,
            });
          }
          self.ruleLoading = false;
        });
    },
    saveHeatMap() {
      var self = this;
      self.ruleLoading = true;
      var formData = new FormData();
      formData.append("deviceInfoId", self.ruleDeviceId);
      formData.append("modeEnabled", self.ruleModel.modeEnabled);
      deviceInfoApi.saveHeatMap(formData).then(function (response) {
        var jsonData = response.data;
        if (jsonData.result) {
          self.$message({
            type: "success",
            message: "成功",
          });
        } else {
          self.$message({
            type: "success",
            message: jsonData.message,
          });
        }
        self.ruleLoading = false;
      });
    },
    handlePoint(record) {
      this.modalTitle = "定位";
      this.businessKey = record.id;
      this.showMapModal = true;
    },
    handleBindPerson(record) {
      var self = this;

      this.$confirm(
        "将" + record.companyName + "中所有关联用户关联到该设备?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        self.loading = true;
        self.loadingText = "关联中...";

        var formData = new FormData();
        formData.append("deviceId", record.id);

        //增加定时器查询关联进度
        self.queryBindDeviceProgress(record.id);

        deviceInfoApi.bindDeviceToPerson(formData).then(function (response) {
          var jsonData = response.data;

          self.loading = false;
          self.loadingText = "";

          if (jsonData.result) {
            self.$message.success("关联成功!新增关联用户数:" + jsonData.data);
          } else {
            self.$message.warning(jsonData.message);
          }
        });
      });
    },
    queryBindDeviceProgress(deviceId) {
      var self = this;

      deviceInfoApi.queryBindDeviceProgress(deviceId).then((response) => {
        var jsonData = response.data;

        if (jsonData.result) {
          self.loadingText =
            "关联人员进度：" +
            (jsonData.data == "null" ? "..." : jsonData.data);
        }

        if (self.loading) {
          //如果没有执行完成则1秒后继续查询
          setTimeout(() => {
            self.queryBindDeviceProgress(deviceId);
          }, 1000);
        }
      });
    },
    handleExistPersonImageInDevice(record) {
      var self = this;

      this.$confirm(
        "确定更新【" +
          record.aliasName +
          "】关联用户图片是否存在设备中状态？此为耗时操作将在后台进行！",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        self.loading = true;
        self.loadingText = "提交中...";
        var formData = new FormData();
        formData.append("deviceId", record.id);

        deviceInfoApi
          .existPersonImageInDevice(formData)
          .then(function (response) {
            var jsonData = response.data;

            self.loading = false;
            self.loadingText = "";

            if (jsonData.result) {
              self.$message.success(jsonData.message + "");
            } else {
              self.$message.warning(jsonData.message + "");
            }
          });
      });
    },
    handleReboot(record) {
      var self = this;

      this.$confirm("是否确定将" + record.aliasName + "重启?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        self.loading = true;
        self.loadingText = "重启中...";

        var formData = new FormData();
        formData.append("deviceId", record.id);

        deviceInfoApi.reboot(formData).then(function (response) {
          var jsonData = response.data;

          self.loading = false;
          self.loadingText = "";

          if (jsonData.result) {
            self.$message.success(jsonData.message + "");
          } else {
            self.$message.warning(jsonData.message + "");
          }
        });
      });
    },
    handleViewPersonLib(device) {
      this.selectedDevice = device;
      this.showPhotoListModal = true;
    },
    onDevicePhotoListClose() {
      this.showPhotoListModal = false;
    },
    handleAcsCfg(device) {
      this.selectedDevice = device;
      this.showEhomeModal = true;
    },
    onEhomeModalClose() {
      this.showEhomeModal = false;
    },
    exportXls() {
      this.changePage(1, true);
    },
    loadLightCfg(record) {
      deviceInfoApi.sendCommand({
        deviceNo : record.deviceNo,
        method: "GET",
        url : "/LAPI/V1.0/Smart/FaceTurnstiles/LightCfg",
      }).then(resp=>{
        var jsonData = resp.data;

        if(jsonData.result){
          this.lightCfg = jsonData.data.Response.Data;
        }
      });
    },
    saveLightCfg() {
      deviceInfoApi.sendCommand({
        deviceNo : this.selectedDevice.deviceNo,
        method: "PUT",
        url : "/LAPI/V1.0/Smart/FaceTurnstiles/LightCfg",
        data: this.lightCfg
      }).then(resp=>{
        var jsonData = resp.data;
        
        if(jsonData.result){
           this.$message.success("设置成功!");
        }
      });
    },
    handleTabClick(tab, event) {
      if(this.activeName=='6' && this.selectedDevice!=null) {
        this.loadLightCfg(this.selectedDevice);
      }
    }
  },
  mounted: function () {
    var self = this;
    this.changePage(1);

    setTimeout(() => {
      self.tableHeight = pageUtil.autoAdjustHeight(self.$refs.formTable.$el);
    }, 1000);
  },
  components: {
    "deviceInfo-detail": DeviceInfoDetail,
    "deviceInfo-channelList": DeviceInfoChannelList,
    "deviceInfo-map": DeviceInfoMap,
    "el-select-tree": SelectTree,
    "devicePhoto-list": DevicePhotoList,
    "deviceInfo-ehome": DeviceInfoEhome,
  },
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>