
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :append-to-body="true"
    style="text-align: left"
    @close="closeDialog"
    :close-on-click-modal="false"
  >
    <div class="user-panel" v-loading="loading">
      <el-table
        ref="formTable"
        :data="tableData"
        :element-loading-text="loadingText"
        highlight-current-row
        stripe
        @current-change="handleCurrentChange"
      >
        <el-table-column
          type="index"
          label="序号"
          :index="indexMethod"
          width="50"
        ></el-table-column>
        <el-table-column
          prop="deviceName"
          label="设备名称"
          width="150"
        ></el-table-column>
        <el-table-column
          prop="deviceId"
          label="设备编号"
          width="150"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column prop="deviceStatus" label="设备状态" width="100">
          <template slot-scope="{ row }">
            <div
              v-if="row.deviceStatus == 1"
              style="
                border-radius: 30px;
                background-color: #67c23a;
                width: 20px;
                height: 20px;
              "
            ></div>
            <div
              v-else-if="!row.deviceStatus == 0"
              style="
                border-radius: 30px;
                background-color: #f56c6c;
                width: 20px;
                height: 20px;
              "
            ></div>
          </template>
        </el-table-column>
        <el-table-column
          prop="deviceModel"
          label="设备型号"
          width="100"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="deviceSerial"
          label="设备序列号"
          width="100"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="devicePath"
          label="设备路径"
          width="100"
          show-overflow-tooltip
        ></el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="pageIndex"
        :total="totalElements"
        :page-sizes="pageSizeList"
        @current-change="changePage"
        @size-change="pageSizeChange"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import companyInfoApi from "@/api/base/companyInfo";
import deviceInfoApi from "@/api/base/deviceInfo";

export default {
  props: ["businessKey", "title", "communityId"],
  data() {
    return {
      formModel: { companyId: "" },
      showDialog: true,
      loading: false,
      submitting: false,
      pageSizeList: [5, 10, 20],
      tableData: [],
      pageIndex: 1,
      pageSize: 5,
      totalPages: 0,
      totalElements: 0,
      loadingText: "",
      currentRow: null,
      deviceId: "",
    };
  },
  created() {},
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    indexMethod(index) {
      return (this.pageIndex - 1) * this.pageSize + (index + 1);
    },
    loadTree() {
      var formData = new FormData();

      companyInfoApi.loadChildren(formData).then((resp) => {
        var jsonData = resp.data;

        if (jsonData.result) {
          this.treeData = jsonData.data;
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    loadChildren(tree, treeNode, resolve) {
      console.log(tree);

      var formData = new FormData();
      formData.append("parentId", tree.id);

      companyInfoApi.loadChildren(formData).then((resp) => {
        var jsonData = resp.data;

        if (jsonData.result) {
          resolve(jsonData.data);
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    changePage(pageIndex, exportFlag) {
      var self = this;

      self.loading = true;
      self.loadingText = "加载中...";

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("communityId", self.communityId);
      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      deviceInfoApi
        .listByCommunityId(formData)
        .then(function (response) {
          self.loading = false;
          var jsonData = response.data;

          if (jsonData.result) {
            var page = jsonData.data;

            self.tableData = page.data;
            self.totalPages = page.totalPages;
            self.totalElements = page.recordsTotal;
          } else {
            self.$message.warning(jsonData.message + "");
          }
        })
        .catch((error) => {
          self.loading = false;
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleCurrentChange(val) {
      this.currentRow = val;
      this.deviceId = val.deviceId;
    },
    handleSubmit() {
      var self = this;
      self.$emit("close", self.deviceId);
    },
  },
  mounted: function () {
    var self = this;
    this.changePage(1);
  },
};
</script>