import request from '@/utils/request'
import constant from '@/constant'


function getXMLConfig(deviceNo,url){
  var encUrl = encodeURIComponent(url);

  return request.get(constant.serverUrl + `/ehome/getXMLConfig?deviceNo=${deviceNo}&url=${encUrl}`);
}

function putXMLConfig(deviceNo,url,jsonData){  
  var encUrl = encodeURIComponent(url);

  return request.put(constant.serverUrl + `/ehome/setXMLConfig?deviceNo=${deviceNo}&url=${encUrl}`, jsonData,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function postXMLConfig(deviceNo,url,jsonData){
    var encUrl = encodeURIComponent(url);

    return request.post(constant.serverUrl + `/ehome/setXMLConfig?deviceNo=${deviceNo}&url=${encUrl}`, jsonData,{
      headers: {
        "Content-Type": "application/json"
      }
    });
}

export default {
    getXMLConfig,putXMLConfig,postXMLConfig
}