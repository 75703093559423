<template>
  <el-dialog
    :visible.sync="showDialog"
    title="设备连接列表"
    append-to-body
    :modal="true"
    style="text-align:left;"
    @close="closeDialog"
    :close-on-click-modal="false"
    width="980px"
  >
    <div>
      <!--
      要resetFields起作用，必须配置:model和prop
      -->
      <el-divider></el-divider>
      <el-row class="button-group">
        <el-button
          type="primary"
          size="small"
          plain
          icon="el-icon"
          :disabled="multipleSelection.length==0"
          @click="showChangeNo"
        >修改IP地址</el-button>
      </el-row>
      <el-table
        :data="tableData"
        style="min-height:400px;"
        height="450px"
        v-loading="loading"
        stripe
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="45"></el-table-column>
        <el-table-column prop="index" label="序号" width="60"></el-table-column>
        <el-table-column prop="deviceNo" label="设备编号" width="190"></el-table-column>
        <el-table-column prop="aliasName" label="设备名称" width="200"></el-table-column>
        <el-table-column prop="companyName" label="所属单位" width="180"></el-table-column>
        <el-table-column prop="remoteAddress" label="连接地址" width="180"></el-table-column>
        <el-table-column prop="isOpen" label="是否开启" width="80">
          <template slot-scope="{row}">{{row.isOpen? "是" : "否"}}</template>
        </el-table-column>
      </el-table>
      <!-------------设备验证规则start------------------>
      <el-dialog
        title="修改设备IP及端口"
        :visible.sync="changeDialogStatus"
        :close-on-click-modal="false"
        append-to-body
        width="500px"
        :modal-append-to-body="false"
        style="text-align: left;"
        element-loading-text="正在操作,请稍等"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-form
          :model="changeModel"
          ref="changeForm"
          :rules="changeValidate"
          inline
          :label-width="'100px'"
          style="text-align:left;"
          v-loading="boundDeviceDisabled"
        >
          <el-row>
            <el-col>
              <el-form-item label="新IP" prop="newAddress">
                <el-input
                  v-model="changeModel.newAddress"
                  placeholder="请输入新IP地址"
                  style="width:200px"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="新端口" prop="newPort">
                <el-input v-model="changeModel.newPort" placeholder="请输入新端口" style="width:100px"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="hideChangeDialog">取 消</el-button>
          <el-button type="primary" @click="saveNewDeviceNo">确 定</el-button>
        </span>
      </el-dialog>
      <!-------------设备验证规则end------------------>
    </div>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import deviceInfoApi from "@/api/base/deviceInfo";

import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  data() {
    var self = this;

    return {
      changeValidate: {
        newAddress: [{ required: true, message: "不能为空", trigger: "blur" }],
        newPort: [{ required: true, message: "不能为空", trigger: "blur" }]
      },
      formModel: {},
      tableData: [],
      showDialog: true,
      loading: false,
      multipleSelection: [],
      newAddress: "",
      newPort: "",
      changeDialogStatus: false,
      changeModel: {
        newAddress: "",
        newPort: ""
      },
      boundDeviceDisabled: false
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    changePage() {
      var self = this;
      self.loading = true;
      deviceInfoApi
        .channelList()
        .then(function(response) {
          self.loading = false;
          var jsonData = response.data;
          self.tableData = jsonData.data;
        })
        .catch(error => {
          self.loading = false;
        });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    showChangeNo() {
      this.changeDialogStatus = true;
    },
    hideChangeDialog() {
      this.changeModel.newAddress = "";
      this.changeModel.newPort = "";
      this.changeDialogStatus = false;
    },
    saveNewDeviceNo() {
      var self = this;
      this.$refs["changeForm"].validate(valid => {
        if (valid) {
          self.boundDeviceDisabled = true;
          var noList = this.multipleSelection.map(record => {
            return record.deviceNo;
          });
          var formData = new FormData();
          formData.append("deviceNos", noList);
          formData.append("newAddress", self.changeModel.newAddress);
          formData.append("newPort", self.changeModel.newPort);

          deviceInfoApi.updateDeviceData(formData).then(function(response) {
            self.boundDeviceDisabled = false;
            var jsonData = response.data;
            if (jsonData.result) {
              self.$message({
                type: "success",
                message: "修改成功"
              });
              self.changePage();
              self.hideChangeDialog();
            } else {
              self.$message({
                type: "warning",
                message: jsonData.message
              });
            }
          });
        }
      });
    }
  },
  mounted: function() {
    this.changePage();
  }
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>