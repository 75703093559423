<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    :append-to-body="true"
    style="text-align:left;"
    width="900px"
    @close="closeDialog"
    :close-on-click-modal="false"
  >
    <el-row>
        <el-col :span="8">
            <ul class="personlib-list">
                <li v-for="lib in personLibs" :key="lib.Name" @click="changeLib(lib)">
                    {{lib.Name}}({{lib.FaceNum}})
                </li>
            </ul>
        </el-col>
        <el-col :span="16">
            <div>
                <el-input placeholder="请输入编号" v-model="qryData" @keyup.enter.native="changePage(1)">
                    <el-button slot="append" icon="el-icon-search" @click="changePage(1)"></el-button>
                </el-input>
            </div>
            <el-table
                :data="personList"
                v-loading="loading"
                :element-loading-text="loadingText"
                style="width: 100%;min-height:400px;">
                <el-table-column label="头像"
                    width="60">
                    <template slot-scope="{row}">
                        <div style="border-radius:50%;width:48px;height:48px;overflow:hidden;">      
                            <template v-if="row.ImageList.length>0">
                                <img :src="'data:image/png;base64,' + row.ImageList[0].Data" style="object-fit:cover;" width="48" height="48"/>
                            </template>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="PersonName"
                    label="姓名"
                    width="150">
                </el-table-column>
                <el-table-column
                    prop="Remarks"
                    label="备注">
                </el-table-column>
                <el-table-column label="操作" width="100" fixed="right">
                    <template slot-scope="{row}">
                    <el-button type="danger" size="mini" @click="handleDelete(row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :current-page.sync="pageIndex"
                :total="totalElements"
                :page-size="pageSize"
                :page-sizes="pageSizeList"
                @current-change="changePage"
                @size-change="pageSizeChange"
                layout="total, sizes, prev, next, jumper"
            ></el-pagination>
        </el-col>
    </el-row>
  </el-dialog>
</template>
<script>
import deviceInfoApi from "@/api/base/deviceInfo";

export default {
  name: 'devicePhotoList',
  props: ["device"],
  data() {
      return {
        showDialog: true,
        title: "设备照片查看",
        tableData: [],
        personLibs: [],
        pageSize: 5,
        pageSizeList: [3,4,5,6],
        pageIndex: 0,
        totalElements: 0,        
        loading: false,
        loadingText: "加载中",
        libId: 0,
        personList: [],
        qryData: ""
      }
  },
  methods: {      
    indexMethod(index) {
      return (this.pageIndex - 1) * this.pageSize + (index + 1);
    },
    closeDialog() {
      this.$emit("close", false);
    },
    changeLib(lib) {
        this.libId = lib.ID;

        this.changePage(1);
    },
    changePage(pageIndex) {
        this.loading = true;
        this.loadingText = "加载中...";
        this.pageIndex = pageIndex;

        var formData = new FormData();

        formData.append("deviceNo", this.device.deviceNo);
        formData.append("libId", this.libId);
        formData.append("offset", (this.pageIndex - 1)*this.pageSize);
        formData.append("limit", this.pageSize);
        formData.append("qryType", 27);
        formData.append("qryData", this.qryData);

        deviceInfoApi.personList(formData).then(response=>{
            this.loading = false;
            var jsonData = response.data;

            if(jsonData.result){
                this.personList = jsonData.data.Response.Data.PersonList.PersonInfoList;
                this.totalElements = jsonData.data.Response.Data.Total;
            }
        });
    },
    pageSizeChange(pageSize) {
        this.pageSize = pageSize;
        this.changePage(1);
    },
    handleDelete(record) {
      var self = this;

      self.$confirm("是否确认删除" + record.PersonName + "?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          deviceInfoApi.deletePerson(this.device.deviceNo,this.libId,record.PersonID)
          .then(function(response) {
            var jsonData = response.data;

            if (jsonData.result) {
              // var index = self.tableData.indexOf(record);
              // self.tableData.splice(index, 1);
              self.queryPersonLib();
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "删除成功!"
              });
            }
          });
        });
    },
    queryPersonLib() {
         deviceInfoApi.personLibList(this.device.deviceNo).then(response=>{
            var jsonData = response.data;
            

            if(jsonData.result){
                this.personLibs = jsonData.data.Response.Data.LibList;
            }
        });
    }
  },
  created() {
      if(this.device!=null){
          this.queryPersonLib();
      }
  }
}
</script>
<style lang="scss">
.personlib-list li{
    line-height:30px;
    cursor:pointer;
}
</style>